import React from 'react'

import clsx from 'clsx'
import { graphql, navigate, PageProps } from 'gatsby'
import { equals } from 'ramda'

import { Page, values } from '@karakuri-ai/faq-component'

import { Layout, Maybe, SettingYaml } from '../graphql'
import IndexLayout from '../layouts'
import { traverse } from '../utils/breadcrumb'
import { loadDictionary } from '../utils/dictionary'
import { SearchParams, SearchPageContext, CategoryDictionary } from '../widgets/interface'
import { renderSearchPageWidget } from '../widgets/pages'
import { SEOWidget as SEO } from '../widgets/SEOWidget'

function mainLayout(layout?: Maybe<Maybe<Layout>[]>) {
  const defaultLayout: Layout[] = [
    { id: 'filterSearch', type: 'filterSearch' },
    { id: 'filterCategory', type: 'filterCategory' },
    { id: 'filterSearchResult', type: 'filterSearchResult' },
  ]
  if (!layout) {
    return defaultLayout
  }
  const main = values(layout, defaultLayout)
  if (main.length === 0) {
    return defaultLayout
  }
  return main
}

interface Data {
  settingYaml: SettingYaml
}

type Props = PageProps<Data, SearchPageContext, SearchParams>
function SearchPage({ data, location, pageContext }: Props) {
  const [q, setQ] = React.useState(location.state?.q || [])
  const [page, setPage] = React.useState(location.state?.page || 1)
  const [dictionary, setDictionary] = React.useState<CategoryDictionary>({})
  React.useEffect(() => {
    loadDictionary().then(dictionary => setDictionary(dictionary))
  }, [])
  React.useEffect(() => {
    if (!location.state || !location.state?.page) {
      setPage(1)
      return
    }
    if (location.state?.q && !equals(location.state?.q, q)) {
      setQ(location.state.q)
    }
    if (location.state?.page && location.state.page !== page) {
      setPage(location.state.page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])
  const { tagCloud } = data.settingYaml
  const breadcrumb = {
    current: pageContext.category?.title || '検索結果',
    url: location.pathname,
    hierarchies: location.state?.hierarchies
      ? location.state.hierarchies
      : traverse(pageContext.category, dictionary)
          .reverse()
          .reduce<{ title: string; link: string }[]>((acc, h) => {
            const prefix = acc.length > 0 ? acc[acc.length - 1].link : '/search'
            acc.push({
              link: `${prefix}/${h.id}`,
              title: h.title,
            })
            return acc
          }, []),
  }
  const category = pageContext.category
  const onSearch = (q: string[], page?: number) => {
    setQ(q)
    if (page) {
      setPage(page)
      const pathname = location.pathname.replace(/\/*$/, '')
      if (page === 1) {
        navigate(`${pathname}/?q=${q.join(' ')}`, { state: { q, page } })
      } else {
        navigate(`${pathname}/?q=${q.join(' ')}&page=${page}`, { state: { q, page } })
      }
    }
  }
  const widgetProps = {
    data,
    breadcrumb,
    tagCloud: !!tagCloud,
    location,
    dictionary,
    q,
    page,
    pageContext,
    onSearch,
  }
  const layout = data.settingYaml.layout || 'one'
  const left = layout === 'one' ? [] : values(data.settingYaml.searchLayoutLeft, [])
  const right = layout === 'three' ? values(data.settingYaml.searchLayoutRight, []) : []
  const main = mainLayout(data.settingYaml.searchLayoutMain)
  return (
    <IndexLayout className="faq-search-layout">
      <SEO pathname={location.pathname} title={category?.title || '検索結果'} />
      <Page
        className={clsx(
          'faq-content',
          'faq-category-page',
          { 'faq-filtered-by-category': category },
          { 'faq-filtered-by-query': q.length !== 0 }
        )}
      >
        <div className="faq-main-content">
          <div className="faq-header">
            <h2>{category?.title || data.settingYaml?.words?.search?.title}</h2>
          </div>
          {renderSearchPageWidget(main, widgetProps)}
        </div>
        <div className={clsx('faq-left-content', { 'faq-no-content': left.length === 0 })}>
          {renderSearchPageWidget(left, widgetProps)}
        </div>
        <div className={clsx('faq-right-content', { 'faq-no-content': right.length === 0 })}>
          {renderSearchPageWidget(right, widgetProps)}
        </div>
      </Page>
    </IndexLayout>
  )
}
export default SearchPage

export const query = graphql`
  query SearchQuery {
    settingYaml(ignore: { ne: true }) {
      botId
      layout
      url
      tagCloud
      waitMs {
        complete
        search
      }
      searchLayoutMain {
        ...Layout
      }
      searchLayoutLeft {
        ...Layout
      }
      searchLayoutRight {
        ...Layout
      }
      categories {
        id
        title
        sub {
          ...SubRecursive
        }
      }
      words {
        search {
          title
          placeholder
          message
          keyword
          numberOfItems
          resultMessage {
            success
            error
          }
        }
        breadcrumb {
          top
        }
      }
      maybeThisOneThreshold
      useAutoSearch
      useSearchWithCategory
      useKnowledgeSearch
      searchType
      queryThreshold
    }
  }
`
